import { useContext, useEffect, useState } from "react";
import md5 from "md5-hash";
import { Modal, Button, Toast } from "react-bootstrap";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { PaymentContext } from "../payment_intent/PaymentContext";
const Vertificatoin = (props) => {
  const {
    show,
    setShow,
    email,
    verfied, setVerfied,
    productId,
    accountId,
    sendVerificationCode,
    vertificationCode, setVertificationCode,checkCodeVerification
  } = useContext(PaymentContext);
  const [NotValid, setNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [RightVertificationCodeMessage, setRightVertificationCodeMessage] =
    useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [counter, setCounter] = useState(0);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  useEffect(() => {
    setCounter(59);
    counterDecrementer(59);
  }, []);
  const counterDecrementer = (count) => {
    if (count > 0) {
      setCounter(count - 1);
      setTimeout(() => {
        counterDecrementer(count - 1);
      }, 1000);
    } else {
      setCounter(0);
    }
  };
  const resendVertifcation = async () => {
    setLoading(true);
    sendVerificationCode();
  };

  function checkValid() {
    checkCodeVerification()
    
    // Request check if Valid
    // setNotValid(false);
    // setLoading(true);
    // const encrybtedCode = md5(vertificationCode);
    // AxiosInstanceUserServer.post("/verify-email-code", {
    //   email: email,
    //   verification_code: encrybtedCode,
    // })
    //   .then((res) => {
    //     console.log(res);
    //     if (res.data.status === "Success") {
          
    //       setVerfied(true);
    //       setRightVertificationCodeMessage(true);
    //       setTimeout(() => {
    //         setRightVertificationCodeMessage(false);
    //         handleClose();
    //         props.setIsSubmitted(true);
    //       }, 2000);
    //     } else {
    //       setNotValid(true);
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log('Something went Wrong');
    //     setErrorToast(true);
    //     setTimeout(() => {
    //       setErrorToast(false);
    //     }, 3000);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
    // if(false ){
    //     setVerfied( true )

    // }
    // else{
    //     setNotValid( true )
    //     setVerfied(false)
    // }
  }
  return (
    <>
      <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>verification Code</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            We have sent you verification code for your email{" "}
            <span style={{ fontWeight: "bolder" }}>{email}</span>
          </p>
          <input
            style={{
              borderRadius: "0.25rem",
              padding: "0.25rem",
              border: `1px solid ${
                RightVertificationCodeMessage ? "green" : "black"
              }`,
            }}
            value={vertificationCode}
            onChange={(e) => {
              setVertificationCode(e.target.value);
            }}
          />

          {NotValid && (
            <span
              style={{ color: "red", display: "block", marginTop: "0.5rem" }}
            >
              Wrong Vertification Code
            </span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <Button
                variant="secondary"
                disabled={loading || counter > 0}
                onClick={resendVertifcation}
              >
                resend verification code
              </Button>
              <Button
                variant="primary"
                onClick={checkValid}
                disabled={loading}
                style={{ marginLeft: "0.5rem" }}
              >
                confirm
              </Button>
            </div>
            {counter > 0 && (
              <span>{`You can't resend until 00:${counter}`}</span>
            )}
          </div>
          {errorToast && (
            <span
              style={{
                color: "red",
                display: "block",
                marginTop: "0.5rem",
                textAlign: "center",
                width: "100%",
              }}
            >
              {" "}
              Somthing Went Wrong
            </span>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Vertificatoin;
