import React from "react";

const QuantityInput = (props) => {
    
    const handleClick = () => {
        props.showQuantityModal(true);
    }


    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1em", width: "100%", flexDirection:'row' }}>
            <button
                style={{
                    width: "fit-content",
                    margin: "0.5em",
                    padding: "0.2em",
                    backgroundColor: "transparent",
                    border: `1px solid ${props.textColor}`,
                    borderRadius: "0.4rem"
                }}
                onClick={handleClick}
            >
                qty <span>{props.quantity} ⌵</span>
            </button>
        
        </div>
    );
}

export default QuantityInput;
