import React, { useState, useEffect } from "react";
import PaymentIntent from "./PaymentIntent";
import CustomerRegister from "./CustomerRegister";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { useParams } from "react-router-dom";
import { Button, Card, Container, Spinner } from "react-bootstrap";
import PaymentForm from "./PaymentForm";
import { BsXCircle } from "react-icons/bs";
import { PiSmileySad } from "react-icons/pi";

function Pay(props) {
  let { payment_uuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [LinkCheck, setLinkCheck] = useState([]);
  const [LinkStatus, setLinkStatus] = useState(null);
  const [outOfStock, setOutOfStock] = useState(false);
  useEffect(() => {
    CheckPaymentLink();
  }, []);

  let apiKey = `${process.env.REACT_APP_API_KEY}`;

  const CheckPaymentLink = async () => {
    try {
      setIsLoading(true);
      const currentURL = window.location.href;
      const res = await AxiosInstanceMENACartServer.post(
        "/api/v2/payment-link/check",
        {
          link_text: currentURL,
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 200) {
        setLinkCheck(res.data);
        setLinkStatus(true);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setLinkStatus(false);
        setIsLoading(false);
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Product is out of stock"
        ) {
          setOutOfStock(true);
        }
      } else {
        throw error;
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ marginBottom: "3%" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {LinkStatus ? (
            <>
              {LinkCheck.price_type === "one_time" ? (
                <div className="payment_intent_style">
                  <PaymentForm paymentCheckResponse={LinkCheck} />
                </div>
              ) : (
                <CustomerRegister
                  account_id={LinkCheck.merchant_id}
                  payment_uuid={payment_uuid}
                  paymentCheckResponse={LinkCheck}
                />
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Container
                  fluid
                  className="min-vh-100 bg-light d-flex align-items-center justify-content-center p-4"
                >
                  <div>
                    <Card
                      className="border-0 shadow-sm hover-shadow "
                      style={{ maxWidth: "400px" }}
                    >
                      <Card.Body className="text-center p-4">
                        <div className="mb-4 d-flex justify-content-center">
                          <div>
                            {outOfStock ? (
                              <>
                                {" "}
                                <PiSmileySad
                                  size={64}
                                  className="text-secondary opacity-75"
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <BsXCircle
                                  size={64}
                                  className="text-danger opacity-75"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {outOfStock ? (
                          <>                            <div>
                          <h2 className="mb-2 fw-bold">
                          Product is out of stock
                          </h2>
                        </div>
</>
                        ) : (
                          <>
                            {" "}
                            <div>
                              <h2 className="mb-2 fw-bold">
                                Payment Link Inactive
                              </h2>
                            </div>
                            <div>
                              <Card.Text className="text-muted mb-4">
                                This payment link is no longer active
                              </Card.Text>
                            </div>
                            <div>
                              <p className="text-muted mb-4">
                                The merchant has cancelled or deactivated this
                                payment link. Please contact the merchant for a
                                new payment link or alternative payment method.
                              </p>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </Container>{" "}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Pay;
