import React, { useContext } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentContext } from "./PaymentContext";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import PaymentCompleted from "./PaymentCompleted";
const SubscriptionCreateWithTrial = ({
  setupClientSecret,
  payment_uuid,
  setupIntentId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    success_url,
    buttonBackgroundColor,
    Customer_id,
    setIsLoading,
    isLoading,
    productId,
    priceId,
    quantity,
    price,
    buttonTextColor,
    cardName,
    email,
    phoneNumber,
    setMessage,
    setPaymentCompleted,
    paymentCompleted,
  } = useContext(PaymentContext);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) return;
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError.message);
        setIsLoading(false);
        return;
      }
      // Confirm the SetupIntent first
      const { error: createPaymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name: cardName,
              email: email,
              phone: phoneNumber,
            },
          },
        });
      const paymentMethodId = paymentMethod.id;
      console.log("paymentMethodId", paymentMethodId);
      if (createPaymentMethodError) {
        setMessage(createPaymentMethodError.message);
        setIsLoading(false);
        return;
      }
      const { error: setupError, setupIntent } = await stripe.confirmCardSetup(
        setupClientSecret,
        {
          payment_method: paymentMethodId,
        }
      );
      if (setupError) {
        setMessage(setupError.message);
        setIsLoading(false);
        return;
      }
      // Now make the backend call to update the default payment method
      const res = await AxiosInstanceMENACartServer.post(
        `/api/v2/customers/update_default_subscription_payment_method/${Customer_id}`,
        {
          payment_method_id: paymentMethodId,
          setup_intent_id: setupIntent.id,
          trial: true,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (res.status === 200) {
        setPaymentCompleted(true);
      } else {
        setMessage("An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      setMessage("An unexpected error occurred. Please try again later.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {paymentCompleted ? (
        <>
          <PaymentCompleted />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          {setupClientSecret && (
            <>
              <PaymentElement id="payment-element" />
              <button
                type="submit"
                disabled={!stripe || !elements || isLoading}
                style={{
                  backgroundColor: buttonBackgroundColor,
                  color: buttonTextColor,
                }}
              >
                {isLoading ? "Loading..." : "Subscribe"}
              </button>
            </>
          )}
        </form>
      )}
    </>
  );
};
export default SubscriptionCreateWithTrial;
