import React, { createContext, useState, useEffect } from "react";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { toast } from "react-toastify";
import md5 from "md5-hash";
import { alignPropType } from "react-bootstrap/esm/types";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStylesLoading, setIsStylesLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [verfied, setVerfied] = useState(false);
  const [setupIntentId, setSetupIntentId] = useState("");
  const [setupClientSecret, setSetupClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);

  const specialCurrencies = [
    "bif",
    "clp",
    "djf",
    "gnf",
    "jpy",
    "kmf",
    "krw",
    "mga",
    "pyg",
    "rwf",
    "ugx",
    "vnd",
    "vuv",
    "xaf",
    "xof",
    "xpf",
  ];
  const [errorToast, setErrorToast] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#5469d4");
  const [buttonTextColor, setButtonTextColor] = useState("white");
  const [backgroundColor, setBackgroundColor] = useState("#5469d4");
  const [textColor, setTextColor] = useState("white");
  const [logo, setLogo] = useState("");
  const [termsAndConditions, setTermAndConditions] = useState("");
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [productId, setProductId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [product, setProduct] = useState({});
  const [price, setPrice] = useState({});
  const [success_url, setSuccess_url] = useState("");
  const [quantityModalShow, setQuantityModalShow] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [Customer_id, setCustomerId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [email, setEmail] = useState("");
  const [cardName, setCardName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(null);
  const [message, setMessage] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(null);
  const [promoMessage, setPromoMessage] = useState("");
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [show, setShow] = useState(false);
  const [vertificationCode, setVertificationCode] = useState("");
  const [SubscriptionDsicount, setSubsicriptionDiscount] = useState(false);
  const [subscriped, setSubscriped] = useState(false);
  const [discountData, setDiscountData] = useState("");
  const [prev, setPrev] = useState(false);
  const [sk, setSk] = useState(null);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [next, setNext] = useState(false);
  const [pi_id, setPi_id] = useState("");
  const [localCustomerId, setLocalCustomerId] = useState(null);

  let trimmedEmail = email.trim();
  let apiKey = `${process.env.REACT_APP_API_KEY}`;
  const updateAccId = (data) => {
    setAccountId(data);
  };
  const updateProdId = (data) => {
    setProductId(data);
  };
  const updatePriceId = (data) => {
    setPriceId(data);
  };

  const sendVerificationCode = async () => {
    try {
      const res = await AxiosInstanceMENACartServer.post(
        `/send-verification-code/${accountId}`,
        { email: trimmedEmail, price_id: priceId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkCodeVerification = async () => {
    try {
      const encrybtedCode = md5(vertificationCode);
      const res = await AxiosInstanceUserServer.post("/verify-email-code", {
        email: trimmedEmail,
        verification_code: encrybtedCode,
      });
      if (res.status === 200 && res.data.status === "Success") {
        toast.success(`${res.data.status}`);
        setVerfied(true);
        setVertificationCode("");
        setShow(false);
      } else {
        toast.error(`${res.data.status}`);
        setVertificationCode("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CheckPromoCode = async (customerId) => {
    try {
      const res = await AxiosInstanceMENACartServer.post(
        `/api/v2/discount/check`,
        {
          price_id: priceId,
          discount_code: promoCode,
          customer_id: customerId,
          amount:
            price.price_type === "recurring"
              ? price.original_amount
              : price.original_amount * quantity,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 200) {
        setDiscountData(res.data);
        setIsPromoCodeValid(true);
        setIsPromoApplied(true);
        setPromoMessage("Promo code applied successfully!");
      } else {
        setIsPromoCodeValid(false);
        setPromoMessage("Invalid promo code.");
      }
    } catch (error) {
      if (error.response.data.error === "Discount code not found") {
        setPromoMessage("Discount code not found");
        setIsPromoCodeValid(false);
      } else if (error.response.data.error === "Discount code has expired") {
        setPromoMessage("Discount code has expired");
        setIsPromoCodeValid(false);
      } else if (
        error.response.data.error === "Discount code has reached its limit"
      ) {
        setPromoMessage("Discount code has reached its limit");
      } else if (
        error.response.data.error ===
        "Cart total amount is less than the minimum required for this discount"
      ) {
        setPromoMessage(
          "Total amount is less than the minimum required for this discount"
        );
      } else if (
        error.response.data.error ===
        "Customer has reached maximum usage for this discount code"
      ) {
        setPromoMessage(
          "Customer has reached maximum usage for this discount code"
        );
      } else {
        setPromoMessage("An error occurred. Please try again.");
        setIsPromoCodeValid(false);
      }
    }
  };
  const check_if_already_subscribed = async (customer_id) => {
    try {
      const res = await AxiosInstanceMENACartServer.get(
        `/api/v2/customer/subscription_check/${accountId}/${customer_id}/${priceId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      // Check if the subscription already exists
      if (res.data.message === "Subscription found") {
        setSubscriped(true);
        return false;
      }
    } catch (error) {
      if (error.response.status === 404) {
        return true;
      } else throw error;
    }
  };

  const CheckCustomer = async () => {
    try {
      const res = await AxiosInstanceMENACartServer.get(
        `/api/v2/customer/check/${trimmedEmail}/${accountId}`,
        { headers: { Authorization: `Bearer ${apiKey}` } }
      );

      if (res.status === 200) {
        const customerId = res.data.customer.id;
        setCustomerId(customerId);
        setLocalCustomerId(customerId);

        if (price.price_type === "recurring") {
          const canCreatePaymentIntent = await check_if_already_subscribed(
            customerId
          );
          if (!canCreatePaymentIntent) return;
          setShow(true);
          await sendVerificationCode();
        } else if (promoCode) {
          CheckPromoCode(customerId);
        } else {
          await createPaymentIntent(customerId); // Explicitly pass customer ID
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // If the customer doesn't exist, create a new customer
        try {
          const CreateCustomer = await AxiosInstanceMENACartServer.post(
            `/api/v2/customer/create`,
            { email: trimmedEmail, name: cardName, merchant_id: accountId },
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
              },
            }
          );

          const newCustomerId = CreateCustomer.data.id;
          setCustomerId(newCustomerId);
          setLocalCustomerId(newCustomerId);

          if (price.price_type === "recurring") {
            setShow(true);
            await sendVerificationCode();
          } else if (promoCode) {
            CheckPromoCode(newCustomerId);
          } else {
            await createPaymentIntent(newCustomerId); // Call with the new ID
          }
        } catch (createError) {
          console.error("Error creating customer:", createError);
        }
      } else {
        console.error("Error checking customer:", error);
      }
    }
  };
  const createPaymentIntent = async (customerId) => {
    if (accountId && customerId) {
      try {
        const res = await AxiosInstanceMENACartServer.post(
          `/create-payment-intent`,
          {
            product_id: productId,
            quantity: quantity,
            price_id: priceId,
            merchant_id: accountId,
            discount_code: promoCode,
            customer_id: customerId,
            amount: discountData
              ? discountData?.final_amount
              : price.original_amount * quantity,
          },
          {
            headers: { "content-type": "application/json" },
          }
        );
        if (res.status === 200 && res.data.clientSecret) {
          setClientSecret(res.data.clientSecret);
          setPi_id(res.data.payment_intent_id);
        } else {
          setPromoMessage(res.data[0]["message"]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getBranding = async () => {
    if (!accountId) return;
    try {
      const res = await AxiosInstanceUserServer.get(
        `/branding/retrieve-branding/${accountId}`
      );
      const data = res.data;
      setButtonBackgroundColor(data.buttonColor);
      setButtonTextColor(data.buttonTextColor);
      setBackgroundColor(data.backgroundColor);
      setTextColor(data.textColor);
      if (data.selectedImage) {
        const url = await getDownloadURL(ref(storage, data.selectedImage));
        setLogo(url);
      }
    } catch (error) {
      setErrorToast(true);
      setTimeout(() => {
        setErrorToast(false);
      }, 3000);
    } finally {
      setIsStylesLoading(false);
    }
  };
  const get_terms_and_conditions = async () => {
    if (accountId) {
      await AxiosInstanceUserServer.get(
        `/merchant/get-terms-and-condtions/${accountId}`
      )
        .then((res) => {
          setTermAndConditions(res.data);

          if (!res.data) {
            setIsChecked(true);
          }
        })
        .catch((error) => {
          // To be Change by aws To Message (Toast)
          setErrorToast(true);
          setTimeout(() => {
            setErrorToast(false);
          }, 3000);
          setErrorAlert(true);
          setIsChecked(false);
          setTermsAndConditionsError(true);
        });
    }
  };

  useEffect(() => {
    getBranding();
    get_terms_and_conditions();
  }, [accountId, productId, priceId]);

  const get_product = async () => {
    setIsLoading(true);
    try {
      const res = await AxiosInstanceMENACartServer.get(
        `/api/v2/merchant/retrieve_secret_key/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      setSk(res.data.secret_key);
      let sk = res.data.secret_key;
      const productResponse = await AxiosInstanceMENACartServer.get(
        `/api/v2/product/retrieve/${productId}/${sk}`
      );

      const productData = productResponse.data.product;
      setProduct(productData);

      if (productData && productData.prices) {
        const matchingPrice = productData.prices.find(
          (price) => price.id === priceId
        );
        if (matchingPrice) {
          setPrice(matchingPrice);
        }
      }
      if (productData && productData.success_url) {
        setSuccess_url(productData.success_url);
      }
    } catch (error) {
      setErrorToast(true);
      setTimeout(() => {
        setErrorToast(false);
      }, 3000);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (accountId) {
      get_product();
    }
  }, [accountId, productId, priceId]);

  return (
    <PaymentContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isStylesLoading,
        setIsStylesLoading,
        isChecked,
        setIsChecked,
        paymentCompleted,
        setPaymentCompleted,
        specialCurrencies,
        errorToast,
        setErrorToast,
        errorAlert,
        setErrorAlert,
        buttonBackgroundColor,
        setButtonBackgroundColor,
        backgroundColor,
        setBackgroundColor,
        buttonTextColor,
        setButtonTextColor,
        textColor,
        setTextColor,
        logo,
        setLogo,
        termsAndConditions,
        setTermAndConditions,
        termsAndConditionsError,
        setTermsAndConditionsError,
        updateAccId,
        updateProdId,
        updatePriceId,
        product,
        price,
        success_url,
        accountId,
        productId,
        priceId,
        quantityModalShow,
        setQuantityModalShow,
        quantity,
        setQuantity,
        CheckCustomer,
        Customer_id,
        email,
        setEmail,
        cardName,
        setCardName,
        promoCode,
        setPromoCode,
        message,
        setMessage,
        isPhoneNumberValid,
        setIsPhoneNumberValid,
        phoneNumber,
        setPhoneNumber,
        CheckPromoCode,
        isPromoCodeValid,
        setIsPromoCodeValid,
        promoMessage,
        setPromoMessage,
        isPromoApplied,
        setIsPromoApplied,
        clientSecret,
        show,
        setShow,
        sendVerificationCode,
        verfied,
        setVerfied,
        vertificationCode,
        setVertificationCode,
        checkCodeVerification,
        createPaymentIntent,
        SubscriptionDsicount,
        setSubsicriptionDiscount,
        prev,
        setPrev,
        setupIntentId,
        setSetupIntentId,
        sk,
        discountData,
        subscriped,
        showPromoInput,
        setShowPromoInput,
        next,
        setNext,
        pi_id,
        setupClientSecret,
        setSetupClientSecret,
        paymentIntentId,
        setPaymentIntentId,
        get_product,
        setDiscountData,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
