import React, { useContext } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentContext } from "./PaymentContext";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import PaymentCompleted from "./PaymentCompleted";

const SubscriptionCreateNoTrail = ({ clientSecret, payment_uuid }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    success_url,
    buttonBackgroundColor,
    Customer_id,
    setIsLoading,
    isLoading,
    setupIntentId,
    productId,
    priceId,
    setupClientSecret,
    quantity,
    buttonTextColor,
    cardName,
    email,
    phoneNumber,
    setMessage,
    paymentIntentId,
    setPaymentCompleted,
    paymentCompleted,
  } = useContext(PaymentContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) return;

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError.message);
        setIsLoading(false);
        return;
      }

      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name: cardName,
              email: email,
              phone: phoneNumber,
            },
          },
        });

      if (paymentMethodError) {
        setMessage(paymentMethodError.message);
        setIsLoading(false);
        return;
      }

      const res = await AxiosInstanceMENACartServer.post(
        `/api/v2/customers/update_default_subscription_payment_method/${Customer_id}`,
        {
          payment_method_id: paymentMethod.id,
          payment_intent_id: paymentIntentId,
          setup_intent_id: setupIntentId,
          trial: false,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (res.status === 200) {
        const { error: setupError } = await stripe.confirmCardSetup(
          setupClientSecret,
          {
            payment_method: paymentMethod.id,
          }
        );

        if (setupError) {
          setMessage(setupError.message);
        } else {
          const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url:
                success_url ||
                `${process.env.REACT_APP_FRONTEND_URL_PAYMENT}/success_url/${payment_uuid}/${productId}/${priceId}/${quantity}`,
            },
            redirect: "if_required",
          });

          if (result.error) {
            setMessage(result.error.message);
          } else {
            setPaymentCompleted(true);
          }
        }
      }
    } catch (error) {
      setMessage("An unexpected error occurred. Please try again later.");
      console.error(error);
    } finally {
      setIsLoading(false); // Always reset loading state at the end
    }
  };

  return (
    <>
      {paymentCompleted ? (
        <>
          <PaymentCompleted />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          {clientSecret && (
            <>
              <PaymentElement id="payment-element" />
              <button
                type="submit"
                disabled={!stripe || !elements || isLoading}
                style={{
                  backgroundColor: buttonBackgroundColor,
                  color: buttonTextColor,
                }}
              >
                {isLoading ? "Loading..." : "Subscribe"}
              </button>
            </>
          )}
        </form>
      )}
    </>
  );
};

export default SubscriptionCreateNoTrail;
