import React from 'react'
import AxiosInstanceMENACartServer from './axios/AxiosInstanceMENACartServer'

const Dummy = () => {
  const handleClick = (element) => { 
    // One-time
    AxiosInstanceMENACartServer.post(`/payment-api/sk_20e56fcc-5f4c-4c34-a5af-2b7de279c6f0`,{
      currency: "eur",
      merchant_id: "mer_123",
      items_list: JSON.stringify([
        {item: 'ball', price: 500, quantity: 3},
        {item: 'saw', price: 50},
        {item: 'glass', price: 3000},
        {item: 'lighter', price: 1500, quantity: 2},
      ]),
    })
    .then(res => {
    //   setValue(res)
    }).catch(err => {
      console.log('err :>> ', err);
    });

    // Recurring
    // console.log('time :>> ', (((new Date()).getTime()) / 1000).toFixed(0));
    // AxiosInstanceMENACartServer.post(`/subscription/create-payment-subscription-intent/sk_0937c0fa-8c0a-44d1-a5f3-ddfa7a83d301`, JSON.stringify({
    //   merchant_id: "mer_1233654789",
    //   price: 50,
    //   currency: "eur",
    //   item_name: "Chicken nuggets KFC",
    //   description: "A quarterly subscription for the unhealthy food",
    //   item_id: "prod_19716",
    //   interval: "week",
    //   interval_count: 3,
    //   duration: 2,
    //   email: "m.alfayoume@fintesa.co",
    // }))
    // .then(res => {
    //     console.log('res :>> ', res);
    //     console.log('res message :>> ', res.message);
    //     console.log('res.data :>> ', res.data);
    //     // setValue(res)
    //   }).catch(err => {
    //     console.log('err :>> ', err);
    //   });
   }
  return (
    <div>
      <button onClick={handleClick}>Click to create</button>
      {/* {value ? <p>{JSON.stringify(value)}</p> : <p>does not work</p>} */}
      
    </div>
  )
}

export default Dummy