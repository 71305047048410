import React, { useState, useEffect, useContext } from "react";
import { Spinner, Form, InputGroup, Button } from "react-bootstrap";
import Vertificatoin from "../module/Vertification";
import { PaymentContext } from "./PaymentContext";
import "./paymentIntent.css";
import SubscriptionNoTrial from "./SubscriptionNoTrial";
import SubscriptionWithTrial from "./SubscriptionWithTrial";

export default function CustomerRegister(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    CheckCustomer,
    price,
    setEmail,
    setCardName,
    updateProdId,
    updateAccId,
    updatePriceId,
    show,
    setShow,
    verfied,
    subscriped,
  } = useContext(PaymentContext);

  useEffect(() => {
    updateAccId(props.paymentCheckResponse?.merchant_id);
    updateProdId(props.paymentCheckResponse?.product_id);
    updatePriceId(props.paymentCheckResponse?.price_id);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading when the process starts

    try {
      // Call the CheckCustomer function
      await CheckCustomer();

      if (verfied) {
        setIsSubmitted(true);
        setIsLoading(false); // Stop loading when submission is done
      } else {
        setIsLoading(false); // Stop loading if verification fails
      }
    } catch (error) {
      console.error("Error creating subscription: ", error);
      setIsLoading(false); // Stop loading if there is an error
    }
  };
  return (
    <div className="App">
      {verfied ? (
        <>
          {price?.trial_period_days === 0  ? (
            <>
              {" "}
              <div className="payment_intent_style">
                <SubscriptionNoTrial payment_uuid={props.payment_uuid} />
              </div>
            </>
          ) : (
            <>          <div className="payment_intent_style">
            <SubscriptionWithTrial payment_uuid={props.payment_uuid} />
          </div>
</>
          )}
        </>
      ) : (
        <>
          <Vertificatoin
            show={show}
            setShow={setShow}
            setIsSubmitted={setIsSubmitted}
          />
          {!isSubmitted && (
            <form onSubmit={handleSubmit} className="login-form">
              <div>
                <h2>Create subscription</h2>
                <Form.Label
                  htmlFor="card-name"
                  style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
                >
                  Email:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="email"
                    type="email"
                    aria-describedby="basic-addon3"
                    style={{ color: "rgb(33,37,41)" }}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputGroup>
                <Form.Label
                  htmlFor="card-name"
                  style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
                >
                  Card Name:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="CardName"
                    aria-describedby="basic-addon3"
                    style={{ color: "rgb(33,37,41)" }}
                    required
                    onChange={(e) => {
                      setCardName(e.target.value);
                    }}
                  />
                </InputGroup>
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{
                        backgroundColor: "#5469d4",
                        width: "1.5rem",
                        height: "1.5rem",
                        border: "0.35rem solid white",
                        borderRightColor: "transparent",
                      }}
                    />
                  ) : (
                    <span>Create subscription</span>
                  )}
                </Button>
                {subscriped && (
                  <span
                    style={{
                      color: "red",
                      display: "block",
                      marginTop: "0.75rem",
                    }}
                  >
                    You already have an active subscription for this product.
                  </span>
                )}
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
}
