import React, { useState, useEffect, useContext } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PaymentContext } from "./PaymentContext";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";

const PayForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { payment_uuid } = props;

  const {
    isLoading,
    setIsLoading,
    isChecked,
    buttonBackgroundColor,
    buttonTextColor,
    termsAndConditions,
    termsAndConditionsError,
    price,
    product,
    success_url,
    accountId, 
    productId,
    priceId,
    quantity,
    clientSecret,
    cardName,
    phoneNumber,
    Customer_id,
    email,
    setIsChecked,
    setPrev,
    pi_id,
  } = useContext(PaymentContext);

  const [message, setMessage] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      setMessage("Stripe has not loaded properly.");
      return;
    }

    setIsLoading(true);

    try {
      // Submit the elements to collect payment details
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError.message);
        setIsLoading(false);
        return;
      }

      // Now, create the payment method
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name: cardName,
              email: email.trim(),
              phone: phoneNumber,
            },
          },
        });

      if (paymentMethodError) {
        setMessage(paymentMethodError.message);
        setIsLoading(false);
        return;
      }
      // Attach the PaymentMethod to the Customer
      await AxiosInstanceMENACartServer.post(
        `/api/v2/customers/update_default_one_time_payment_method/${Customer_id}`,{
          payment_method_id: paymentMethod.id,
          payment_intent_id:pi_id

        },{
          headers: { 'Content-Type': 'application/json'}
        }
      );

      // Confirm the payment using the PaymentElement
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            success_url ||
            `${process.env.REACT_APP_FRONTEND_URL_PAYMENT}/success_url/${payment_uuid}/${productId}/${priceId}/${quantity}/${accountId}`,
        },
      });

      if (error) {
        setMessage(error.message);
        setIsLoading(false);
        return;
      }

      // The user will be redirected to the return_url upon completion
    } catch (error) {
      console.error("Error during payment submission:", error);
      setMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!stripe || !price || !product) return;

    const pr = stripe.paymentRequest({
      country: "US",
      currency: price.original_currency_code || "usd",
      total: {
        label: product.name || "Product",
        amount:
          (quantity
            ? Math.round(price.amount * 100) * quantity
            : Math.round(price.amount * 100)) || 0,
      },
      displayItems: [
        {
          label: `${product.name} ${quantity ? `x ${quantity}` : ""}`,
          amount: quantity
            ? Math.round(price.amount * 100) * quantity
            : Math.round(price.amount * 100),
        },
      ],
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) setPaymentRequest(pr);
    });

    pr.on("paymentmethod", async (ev) => {
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        ev.complete("fail");
        setMessage("Payment confirmation failed. Please try again.");
      } else {
        ev.complete("success");
        navigate(
          `/success_url/${payment_uuid}/${productId}/${priceId}/${quantity}`
        );
      }
    });
  }, [
    stripe,
    clientSecret,
    price,
    product,
    quantity,
    payment_uuid,
    priceId,
    productId,
    navigate,
  ]);

  const handleBack = () => {
    setPrev(true);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        id="payment-form"
        style={{ border: message ? "1px solid red" : "none" }}
      >
        <PaymentElement id="payment-element" />
        <div className="d-flex gap-5">
          <button
            style={{
              backgroundColor: buttonBackgroundColor,
              color: buttonTextColor,
            }}
            onClick={handleBack}
          >
            <span id="button-text">Back</span>
          </button>
          <button
            disabled={
              isLoading ||
              !stripe ||
              !elements ||
              !isChecked ||
              termsAndConditionsError
            }
            id="payment-submit"
            style={{
              backgroundColor: buttonBackgroundColor,
              color: buttonTextColor,
            }}
          >
            <span id="button-text">
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{ color: buttonTextColor }}
                />
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </div>

        {paymentRequest && (
          <>
            <hr />
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </>
        )}

        {termsAndConditions && (
          <div className="termsconditions_checkbox">
            <input
              type="checkbox"
              onChange={() => setIsChecked(!isChecked)}
              checked={isChecked}
            />
            <label>
              Accept{" "}
              <a href={termsAndConditions} target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>
            </label>
          </div>
        )}
      </form>

      {message && (
        <Alert
          variant="danger"
          className="alert-message"
          style={{ color: "red", marginTop: "1rem", textAlign: "center" }}
          id="payment-message"
        >
          {message}
        </Alert>
      )}
    </>
  );
};

export default PayForm;
