/** @format */

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Row, Container } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import PaymentIntent from "./components/payment_intent/PaymentIntent";
import CustomerRegister from "./components/payment_intent/CustomerRegister";
// Pages
import PaymentLinkGeneration from "./main-pages/PaymentLinkGeneration";
import NotFound from "./main-pages/NotFound";
import InternalServerErrorPage from "./main-pages/InternalServerErrorPage";
import PaymentCompleted from "./components/payment_intent/PaymentCompleted";
import Pay from "./components/payment_intent/Pay";
import CurrencySubscritptionError from "./main-pages/CurrencySubscritptionError";
import UpdatePaymentMethod from "./main-pages/UpdatePaymentMethod";
import AddPaymentMethod from "./main-pages/AddPaymentMethod";
import ApiIntegration from "./modules/apiIntegration/ApiIntegration";
import ApiSuccess from "./modules/apiIntegration/ApiSuccess";
import ApiSubscriptionIntegration from "./modules/apiIntegration/ApiSubscriptionIntegration";
import { FintesaPlans } from "./main-pages/FintesaPlans";
import { SubscriptionPayment } from "./main-pages/SubscriptionPayment";
import Integrations from "./main-pages/Integrations";
import IntegrationSuccess from "./main-pages/IntegrationSuccess";
import Dummy from "./components/Dummy";
import OneTimePaymentFintesa from "./main-pages/OneTimePaymentFintesa";
import { ImageProvider } from "./ImageContext";
import { PaymentProvider } from "./components/payment_intent/PaymentContext";
function App() {
  // selectedMerchantData
  // const [selectedMerchantData, setSelectedMerchantData] = useState([]);
  // Function to clear complete cache data
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    clearCacheData();
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loading]);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
    // selectedMerchantData
  // function merchantRedirect(data) {
  //   let merchantsIds = [];
  //   data.merchants.map((merchant, idx) => {
  //     merchantsIds.push(merchant.id);
  //     if (merchant.id === idQuery) {
  //       setSelectedMerchantData(merchant);
  //       setIdx(idx);
  //     }
  //   });
  //   // If there is no Id || the Id not in the Merchant List
  //   if (!idQuery || !merchantsIds.includes(idQuery)) {
  //     setMerchantsListModalShow(true);
  //   } else {
  //     getAllChargesData(idQuery);
  //     getAllProductsData(idQuery);
  //     getAllBankAccounts(idQuery);
  //   }
  // }
  return (
    <>
    <ImageProvider>
    <PaymentProvider>

    <ToastContainer />
      {loading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "100hv",
            marginTop: "330px",
          }}
        >
          <BeatLoader color="#304DAF" size="20px" />
        </div>
      ) : (
        <Row
          style={{
            backgroundColor: "rgba(248, 249, 253, 1)",
            margin: "0",
            padding: "0",
          }}
        >
          <Router>
            <Row style={{ margin: "auto" , padding: 0}}>
              <Container
                fluid
                style={{
                  margin: "0",
                  padding: "0",
                  backgroundColor: "rgba(248, 249, 253, 1)",
                }}
              >
                <Routes>
                  {/* Payment Link Generation Integration */}
                  <Route
                    path="/payment-link-generation"
                    element={<PaymentLinkGeneration />}
                  ></Route>

                  {/* Not Found Page */}
                  <Route path="*" element={<NotFound />}></Route>

                  {/* Server Error Page */}
                  <Route
                    path="/500"
                    element={<InternalServerErrorPage />}
                  ></Route>

                  {/* Payment Intent Page */}
                  <Route
                    path="/payment-intent/:payment_uuid/:product_id/:price_id"
                    element={<Pay/>}
                  ></Route>
                  {/* Payment Complete Page */}
                   <Route
                    path="/success_url/:payment_uuid/:product_id/:price_id/:quantity/:id"
                    element={<PaymentCompleted />}
                  ></Route>
                  {/* API Payment Page */}
                  <Route
                    path="/payment-intent/:merchant_uuid/:client_secret"
                    element={<ApiIntegration />}
                  ></Route>
                  {/* API Payment Success */}
                  <Route
                    path="/payment-success/:merchant_uuid/:client_secret/:sub"
                    element={<ApiSuccess />}
                  ></Route>
                  <Route
                    path="/temp"
                    element={<Dummy />}
                  ></Route>
                  
                  <Route
                    path="/subscription-intent/:merchant_uuid/:client_secret"
                    element={<ApiSubscriptionIntegration />}
                  ></Route>
                   <Route
                    path="/subscriptionerror"
                    element={<CurrencySubscritptionError />}
                  ></Route>
                    <Route
                    path="/update-payment-method/:id/:customer/:subscription/:productId/:priceId"
                    element={<UpdatePaymentMethod />}
                  ></Route>
                  <Route
                    path="/add-payment-method/:id/:customer"
                    element={<AddPaymentMethod />}
                  ></Route>
                  <Route
                  path="/plans/:productId/:priceId"
                  element={<FintesaPlans />}
                  />
                  <Route
                  path="/subscription-intent/:uuid/:clientSecret/:productId/:priceId"
                  element={<SubscriptionPayment />}
                  />

                  <Route
                  path="/integrations/:productId/:priceId"
                  element={<Integrations />}
                  />
                  <Route
                  path="/payment-success/integrations/:productId/:priceId/:qty"
                  element={<IntegrationSuccess />}
                  />
                  <Route
                    path="/one-time-payment/:productId/:priceId"
                    element={<OneTimePaymentFintesa />}
                  />
                </Routes>
              </Container>
            </Row>
          </Router>
        </Row>
      )}
      </PaymentProvider>
      </ImageProvider>
    </>
  );
}
export default App;
