import React, { useContext, useState, useEffect } from "react";
import { Form, InputGroup, Button, Collapse, Row, Col } from "react-bootstrap";
import PhoneNumberInput from "../inputs/PhoneNumberInput";
import { useParams } from "react-router-dom";
import { PaymentContext } from "./PaymentContext";
import { Elements } from "@stripe/react-stripe-js";
import PayForm from "./PayForm";
import { loadStripe } from "@stripe/stripe-js";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";

const PayFormNew = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const { payment_uuid } = useParams();
  const {
    CheckCustomer,
    email,
    setEmail,
    cardName,
    setCardName,
    clientSecret,
    promoCode,
    setPromoCode,
    message,
    isPhoneNumberValid,
    phoneNumber,
    setPhoneNumber,
    isPromoCodeValid,
    buttonBackgroundColor,
    buttonTextColor,
    promoMessage,
    setPromoMessage,
    isPromoApplied,
    setIsPromoApplied,
    accountId,
    price,
    prev,
    setPrev,
    createPaymentIntent,
    Customer_id,
    showPromoInput,
    setShowPromoInput,
    next,
    setNext,
  } = useContext(PaymentContext);

  useEffect(() => {
    const loadStripeData = async () => {
      const stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
      if (!stripe_publish_key) {
        console.error("Stripe publishable key is missing.");
        return;
      }
      const stripe = await loadStripe(stripe_publish_key, {
        stripeAccount: accountId,
      });
      setStripePromise(stripe);
    };

    loadStripeData();
  }, [accountId]);

  useEffect(() => {
    if (clientSecret) {
      setNext(true);
    }
  }, [clientSecret]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setPrev(false);
    try {

      if (Customer_id && isPromoCodeValid && promoCode !== "") {
        await createPaymentIntent(Customer_id);
      } else if (promoCode === "") {
        CheckCustomer();
        createPaymentIntent();
      }
    } catch (error) {
      console.error("Error in CheckCustomer:", error);
    }
  };

  const isPayButtonDisabled = () => {
    if (showPromoInput) {
      if (!isPromoApplied || isPromoCodeValid === false) {
        return true;
      }
    }
    return !email || !cardName;
  };

  return (
    <div>
      {next && stripePromise && prev === false ? (
        <Elements
          options={{ clientSecret, paymentMethodCreation: "manual" }}
          stripe={stripePromise}
        >
          <PayForm payment_uuid={payment_uuid} stripe={stripePromise} />
        </Elements>
      ) : (
        <form
          onSubmit={handleSubmit}
          id="payment-form"
          style={{ border: message ? "1px solid red" : "none" }}
        >
          <Form.Label
            htmlFor="email"
            style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
          >
            Email
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="email"
              aria-describedby="basic-addon3"
              style={{ color: "rgb(33,37,41)" }}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>

          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            payment_uuid={payment_uuid}
            isValid={isPhoneNumberValid}
          />

          <Form.Label
            htmlFor="card-name"
            style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
          >
            Name on card
          </Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              id="card-name"
              aria-describedby="basic-addon3"
              style={{ color: "rgb(33,37,41)" }}
              value={cardName}
              required
              onChange={(e) => setCardName(e.target.value)}
            />
          </InputGroup>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              type="submit"
              variant="light"
              disabled={isPayButtonDisabled()}
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
              }}
            >
              Pay
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PayFormNew;
