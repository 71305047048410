import React, { useContext, useEffect } from "react";
import { PaymentContext } from "./PaymentContext";
import { BsCheckCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";

const PaymentCompleted = () => {
  const { price_id, id, product_id } = useParams();
  const {
    get_product,
    price,
    product,
    updatePriceId,
    updateAccId,
    updateProdId,
  } = useContext(PaymentContext);
  useEffect(() => {
    updatePriceId(price_id);
    updateAccId(id);
    updateProdId(product_id);
    get_product();
  }, []);
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="max-w-md w-80">
        <div className="text-center mb-4">
          <BsCheckCircle color="green" size={64} className="mb-4" />
          <h3 className="text-2xl font-bold">
            {" "}
            {price.price_type === "one_time"
              ? "Thanks for your purchase!"
              : "Thanks for your subscription!"}
          </h3>
        </div>
        <div>
          <div className="space-y-4">
            {price.price_type === "one_time" ? (
              <>
                <p>
                  You've purchased <strong>{product.name}</strong> - at a price
                  of{" "}
                  <strong>
                    {price.original_symbol.toUpperCase()}
                    {price.original_amount}
                  </strong>
                  .
                </p>
              </>
            ) : (
              <>
                <p>
                  You've subscribed to the <strong>{product.name}</strong> -{" "}
                  <strong>{price.name}</strong> plan for{" "}
                  <strong>
                    {price.interval_count} {price.interval}
                  </strong>{" "}
                  at a price of{" "}
                  <strong>
                    {price.original_symbol}
                    {price.original_amount}
                  </strong>
                  .
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCompleted;
