import React, { useContext, useEffect, useState } from "react";
import { PaymentContext } from "./PaymentContext";
import QuantityInput from "../inputs/QuantityInput";
import QuantityModal from "../modals/QuantityModal";
import {
  Container,
  Row,
  Col,
  Toast,
  Alert,
  InputGroup,
  Button,
  Form,
} from "react-bootstrap";
import ImageContext from "../../ImageContext";
import PayFormNew from "./PayFormNew";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { BsFillTagFill } from "react-icons/bs";

const PaymentForm = (props) => {
  const {
    updateProdId,
    updateAccId,
    updatePriceId,
    product,
    price,
    backgroundColor,
    textColor,
    logo,
    discountData,
    setDiscountData,
    errorToast,
    errorAlert,
    quantityModalShow,
    setQuantityModalShow,
    quantity,
    setQuantity,
    next,
    prev,
    cardName,
    email,
    CheckCustomer,
    showPromoInput,
    setShowPromoInput,
    promoCode,
    setIsPromoApplied,
    setPromoCode,
    setPromoMessage,
    buttonBackgroundColor,
    buttonTextColor,
    promoMessage,
    isPromoCodeValid,
    setIsPromoCodeValid,
    isPromoApplied,
  } = useContext(PaymentContext);

  const [total, setTotal] = useState(0);

  // Effect to recalculate total when quantity or discount changes
  useEffect(() => {
    if (price?.original_amount) {
      const baseTotal = price.original_amount * quantity;
      setTotal(
        discountData?.discount_check
          ? discountData?.final_amount
          : baseTotal?.toFixed(2)
      );
    }
  }, [quantity, discountData, price?.original_amount]);

  // Effect to reapply promo code when quantity changes
  useEffect(() => {
    if (isPromoCodeValid && promoCode) {
      handlePromoCode();
    }
  }, [quantity]);

  const handlePromoCode = async () => {
    if (!email || !cardName) {
      toast.error("Both email and name are required to apply the promo code.");
      return;
    }
    CheckCustomer();
  };

  const handleRemovePromo = async () => {
    setTotal(price?.original_amount * quantity)?.toFixed(2);
    setDiscountData(null);
    setShowPromoInput(false);
    setIsPromoApplied(false);
    setPromoMessage(" ");
    setPromoCode("");
    setIsPromoCodeValid(false);
  };
  useEffect(() => {
    if (promoCode === "" && setIsPromoCodeValid === false) {
      CheckCustomer();
    }

    
  }, [promoCode, setIsPromoCodeValid]);

  const { PaymentImages } = useContext(ImageContext);

  useEffect(() => {
    updateProdId(props.paymentCheckResponse?.product_id);
    updateAccId(props.paymentCheckResponse?.merchant_id);
    updatePriceId(props.paymentCheckResponse?.price_id);
  }, [props.paymentCheckResponse]);

  return (
    <Container fluid className="p-0">
      {quantityModalShow && (
        <QuantityModal
          price={price}
          show={quantityModalShow}
          setShow={setQuantityModalShow}
          setQuantity={setQuantity}
          quantity={quantity}
        />
      )}

      <Row className="g-0">
        {/* Left Panel */}
        <Col md={6} className="h-100">
          <div
            className="d-flex flex-column min-vh-100"
            style={{ backgroundColor }}
          >
            <div
              className="d-flex flex-column justify-content-center px-4 py-5 my-auto"
              style={{ width: "480px", margin: "0 auto" }}
            >
              {/* Logo Section */}
              <div className="mb-5">
                {logo ? (
                  <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid rounded-circle"
                    style={{
                      width: "100px",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  PaymentImages && (
                    <img
                      src={PaymentImages["Fintesa.png"]}
                      alt="Logo"
                      className="img-fluid rounded-circle"
                      style={{
                        width: "100px",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    />
                  )
                )}
              </div>

              {/* Product Details */}
              <div className="mb-4">
                <h1
                  className="mb-4 fw-bold"
                  style={{
                    color: textColor,
                    fontSize: "clamp(1.5rem, 4vw, 2.5rem)",
                  }}
                >
                  {product?.name}
                </h1>

                <h3
                  className="mb-4"
                  style={{
                    color: textColor,
                    fontSize: "clamp(1.2rem, 3vw, 2rem)",
                  }}
                >
                  {price?.original_currency_code?.toUpperCase()}{" "}
                  {(price?.original_amount * quantity).toFixed(2)} / per Item
                </h3>

                <p
                  className="mb-4"
                  style={{
                    color: textColor,
                    fontSize: "clamp(1rem, 2vw, 1.25rem)",
                  }}
                >
                  {product?.description}
                </p>
                <hr style={{ color: textColor }} className="mb-4" />

                {next && prev === false ? null : (
                  <>
                    {showPromoInput ? (
                      <>
                        {isPromoCodeValid ? (
                          <>
                            <div className="mb-3">
                              <div className="d-flex align-items-center">
                                <span
                                  className="promo-code px-2 py-1 rounded"
                                  style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    fontSize: "0.875rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <BsFillTagFill
                                    className="me-2"
                                    style={{ color: "#fff" }}
                                  />
                                  {promoCode}
                                  {discountData?.discount_percentage > 0 &&
                                    ` (${discountData?.discount_percentage}% off)`}
                                </span>
                                <MdClose
                                  color={textColor}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginInlineStart: "1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleRemovePromo();
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <InputGroup>
                              <Form.Control
                                id="promo-code"
                                aria-describedby="basic-addon3"
                                style={{ color: "rgb(33,37,41)" }}
                                value={promoCode}
                                placeholder="Add promotion code"
                                onChange={(e) => {
                                  setPromoCode(e.target.value);
                                  setIsPromoApplied(false);
                                }}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginInlineStart: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={handleRemovePromo}
                              >
                                <MdClose />
                              </span>
                            </InputGroup>
                            {promoCode && (
                              <span
                                type="button"
                                onClick={handlePromoCode}
                                disabled={!promoCode}
                                style={{
                                  backgroundColor: buttonTextColor,
                                  color: buttonBackgroundColor,
                                  width: "20%",
                                  padding: "0.2rem",
                                  borderRadius: "0.3rem",
                                  marginTop: "1rem",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                Apply
                              </span>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <span
                        type="button"
                        className="mb-4 p-2 opacity-50 rounded"
                        style={{
                          backgroundColor: buttonTextColor,
                          color: buttonBackgroundColor,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPromoInput(true)}
                      >
                        Add promotion code
                      </span>
                    )}
                    {promoMessage && (
                      <p
                        style={{
                          color: promoMessage.includes(
                            "Promo code applied successfully"
                          )
                            ? "green"
                            : "red",
                        }}
                      >
                        {promoMessage}
                      </p>
                    )}
                    <div className="d-flex align-items-center mt-2">
                      <label className="me-3" style={{ color: textColor }}>
                        Quantity:
                      </label>
                      <div style={{ maxWidth: "150px" }}>
                        <QuantityInput
                          showQuantityModal={setQuantityModalShow}
                          quantity={quantity}
                          amount={price?.original_amount}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-3">
                  {discountData?.discount_check && isPromoApplied && (
                    <p
                      style={{
                        color: textColor,
                        textDecoration: "line-through",
                      }}
                    >
                      Original: {price?.original_currency_code?.toUpperCase()}{" "}
                      {(price?.original_amount * quantity).toFixed(2)}
                    </p>
                  )}
                  <p
                    className="mb-4"
                    style={{
                      color: textColor,
                      fontWeight: discountData?.discount_check
                        ? "bold"
                        : "normal",
                    }}
                  >
                    Total: {price?.original_currency_code?.toUpperCase()}{" "}
                    {total}
                  </p>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div
              className="mt-auto p-4 text-center"
              style={{ color: textColor }}
            >
              <span>
                Powered by{" "}
                <a
                  href="https://fintesa.co/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: textColor }}
                >
                  Fintesa
                </a>
              </span>
              {" | "}
              <span>
                <a
                  href="https://fintesa.co/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: textColor }}
                >
                  Terms
                </a>
              </span>
            </div>
          </div>
        </Col>

        {/* Right Panel - Payment Form */}
        <Col md={6}>
          <div className="p-4">
            <PayFormNew />
          </div>
        </Col>
      </Row>

      {/* Toasts and Alerts */}
      <Toast className="position-fixed top-0 end-0 m-3" show={errorToast}>
        <Toast.Body>Something went wrong!</Toast.Body>
      </Toast>

      <Alert
        variant="danger"
        className="position-fixed top-0 start-50 translate-middle-x m-3"
        show={errorAlert}
      >
        Something Went Wrong
      </Alert>
    </Container>
  );
};

export default PaymentForm;
