import React, { useContext, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Toast,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { PaymentContext } from "./PaymentContext";
import ImageContext from "../../ImageContext";
import "./paymentIntent.css";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { BsFillTagFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import SubscriptionCreateWithTrial from "./SubscriptionCreateWithTrial";

const LoadingSpinner = () => (
  <div className="d-flex justify-content-center align-items-center ">
    <Spinner animation="border" role="status" size="sm" className="me-2" />
    <span>Loading...</span>
  </div>
);

const SubscriptionWithTrial = (props) => {
  const { PaymentImages } = useContext(ImageContext);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [isLoadingStripe, setIsLoadingStripe] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingPromo, setIsLoadingPromo] = useState(false);

  const {
    promoCode,
    setPromoCode,
    CheckPromoCode,
    isPromoCodeValid,
    promoMessage,
    setPromoMessage,
    setIsPromoApplied,
    price,
    product,
    errorToast,
    errorAlert,
    buttonBackgroundColor,
    textColor,
    logo,
    backgroundColor,
    sk,
    Customer_id,
    priceId,
    discountData,
    setDiscountData,
    accountId,
    buttonTextColor,
    setIsPromoCodeValid,
    isPromoApplied,
  } = useContext(PaymentContext);

  const [setupClientSecret, setsetupClientSecret] = useState("");
  const [setupIntentId, setsetupIntentId] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [total, setTotal] = useState(price.original_amount);

  useEffect(() => {
    const loadStripeData = async () => {
      setIsLoadingStripe(true);
      try {
        const stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
        if (!stripe_publish_key) {
          console.error("Stripe publishable key is missing.");
          return;
        }
        const stripe = await loadStripe(stripe_publish_key, {
          stripeAccount: accountId,
        });
        setStripePromise(stripe);
      } catch (error) {
        console.error("Error loading Stripe:", error);
        toast.error("Failed to load payment system");
      } finally {
        setIsLoadingStripe(false);
      }
    };

    loadStripeData();
  }, [accountId]);

  const handleRemovePromo = () => {
    setTotal(price.original_amount);
    setDiscountData(null);
    setShowPromoInput(false);
    setIsPromoApplied(false);
    setPromoMessage("");
    setPromoCode("");
    setIsPromoCodeValid(false);
  };
  const handlePromoCode = async () => {
    setIsLoadingPromo(true);
    try {
      await CheckPromoCode(Customer_id);
    } finally {
      setIsLoadingPromo(false);
    }
  };
  useEffect(() => {
    if (discountData?.discount_check) {
      setTotal(discountData?.final_amount);
    }
  }, [isLoadingPromo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPromoMessage("");
    setIsLoadingPayment(true);
    try {
      const res = await AxiosInstanceMENACartServer.post(
        `/api/v2/subscription/create/${sk}`,
        {
          customer_id: Customer_id,
          price_id: priceId,
          discount_id: discountData ? price.discount_id : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.setupClientSecret && res.data.setupIntentId) {
        setsetupClientSecret(res.data.setupClientSecret);
        setsetupIntentId(res.data.setupIntentId);
        setShowPaymentForm(true);
      }
    } catch (error) {
      if (
        error.response?.data?.message ===
        "Customer already has a subscription to this price"
      ) {
        toast.warning(error.response.data.message);
      } else {
        toast.error("Failed to process payment");
      }
    } finally {
      setIsLoadingPayment(false);
    }
  };

  if (isLoadingStripe) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <Container fluid className="p-0">
      <Row className="g-0">
        <Col lg={showPaymentForm ? 6 : 12} className="h-100">
          <div
            className="d-flex flex-column min-vh-100"
            style={{ backgroundColor }}
          >
            <div
              className="d-flex flex-column justify-content-center px-3 px-md-4 py-4 py-md-5 my-auto"
              style={{
                maxWidth: "480px",
                margin: "0 auto",
                width: "100%",
              }}
            >
              {/* Logo Section */}
              <div className="mb-4 mb-md-5 text-center">
                {logo ? (
                  <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid rounded-circle"
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  PaymentImages && (
                    <img
                      src={PaymentImages["Fintesa.png"]}
                      alt="Logo"
                      className="img-fluid rounded-circle"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                  )
                )}
              </div>

              {/* Product Details */}
              <div className="mb-4">
                <h1
                  className="mb-4 fw-bold text-center text-md-start"
                  style={{
                    color: textColor,
                    fontSize: "clamp(1.25rem, 3vw, 2rem)",
                  }}
                >
                  {product?.name}
                </h1>

                <h3
                  className="mb-4 text-center text-md-start"
                  style={{
                    color: textColor,
                    fontSize: "clamp(1rem, 2.5vw, 1.5rem)",
                  }}
                >
                  {price?.original_currency_code?.toUpperCase()}{" "}
                  {price?.original_amount} / {price.interval_count}{" "}
                  {price.interval}
                </h3>

                <p
                  className="mb-4 text-center text-md-start"
                  style={{
                    color: textColor,
                    fontSize: "clamp(0.875rem, 1.5vw, 1rem)",
                  }}
                >
                  {product?.description}
                </p>
                <hr style={{ color: textColor }} className="mb-4" />
                <p
                  className="mb-2"
                  style={{
                    color: textColor,
                    fontSize: "clamp(0.875rem, 1.5vw, 1rem)",
                  }}
                >
                  This product have a free trial of {price.trial_period_days}{" "}
                  days ! You will be charged once the {price.trial_period_days}{" "}
                  days ends
                </p>
                {/* Promo Code Section */}
                {!showPaymentForm && (
                  <div className="mb-4">
                    {showPromoInput ? (
                      <>
                        {isPromoCodeValid ? (
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                              <span
                                className="promo-code px-2 py-1 rounded"
                                style={{
                                  backgroundColor: "#007bff",
                                  color: "#fff",
                                  fontSize: "0.875rem",
                                  fontWeight: "bold",
                                }}
                              >
                                <BsFillTagFill
                                  className="me-2"
                                  style={{ color: "#fff" }}
                                />
                                {promoCode}
                              </span>
                              <MdClose
                                color={textColor}
                                style={{
                                  marginLeft: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleRemovePromo();
                                }}                              />
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <InputGroup className="mb-2">
                              <Form.Control
                                id="promo-code"
                                placeholder="Add promotion code"
                                value={promoCode}
                                onChange={(e) => {
                                  setPromoCode(e.target.value);
                                  setIsPromoApplied(false);
                                }}
                                disabled={isLoadingPromo}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleRemovePromo();
                                }}                              >
                                <MdClose />
                              </span>
                            </InputGroup>
                            {promoCode && (
                              <span
                                type="button"
                                onClick={handlePromoCode}
                                disabled={!promoCode}
                                style={{
                                  backgroundColor: buttonTextColor,
                                  color: buttonBackgroundColor,
                                  width: "20%",
                                  padding: "0.2rem",
                                  borderRadius: "0.3rem",
                                  marginTop: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                Apply
                              </span>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="text-center text-md-start">
                        <Button
                          variant="outline-secondary"
                          className="mb-3"
                          style={{
                            backgroundColor: buttonTextColor,
                            color: buttonBackgroundColor,
                            opacity: 0.5,
                          }}
                          onClick={() => setShowPromoInput(true)}
                        >
                          Add promotion code
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {promoMessage && (
                  <p
                    className="text-center text-md-start mb-4"
                    style={{
                      color: promoMessage.includes(
                        "Promo code applied successfully"
                      )
                        ? "green"
                        : "red",
                    }}
                  >
                    {promoMessage}
                  </p>
                )}

                <div className="mt-3">
                  {discountData?.discount_check && isPromoApplied && (
                    <p
                      style={{
                        color: textColor,
                        textDecoration: "line-through",
                      }}
                    >
                      Original:{price?.original_amount} / {price.interval_count}{" "}
                      {price.interval}
                    </p>
                  )}
                  <p
                    className="mb-4"
                    style={{
                      color: textColor,
                      fontWeight: discountData?.discount_check
                        ? "bold"
                        : "normal",
                    }}
                  >
                    Total: {price?.original_currency_code?.toUpperCase()}{" "}
                    {total} / {price.interval_count} {price.interval}
                  </p>
                </div>
                {!showPaymentForm && (
                  <div className="text-center text-md-start">
                    <Button
                      style={{
                        backgroundColor: buttonTextColor,
                        color: buttonBackgroundColor,
                        width: "100%",
                      }}
                      onClick={handleSubmit}
                      disabled={isLoadingPayment}
                    >
                      {isLoadingPayment ? <LoadingSpinner /> : "Pay"}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {/* Footer */}
            <div
              className="mt-auto p-4 text-center"
              style={{ color: textColor }}
            >
              <span className="d-inline-block">
                Powered by{" "}
                <a
                  href="https://fintesa.co/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: textColor }}
                >
                  Fintesa
                </a>
              </span>
              <span className="mx-2">|</span>
              <span className="d-inline-block">
                <a
                  href="https://fintesa.co/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: textColor }}
                >
                  Terms
                </a>
              </span>
            </div>
          </div>
        </Col>

        {/* Payment Form Column */}
        {showPaymentForm && setupClientSecret && setupIntentId && (
          <Col lg={6} className="h-100 overflow-auto">
            <div className="form_payments">
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: setupClientSecret,
                  paymentMethodCreation: "manual",
                }}
              >
                <SubscriptionCreateWithTrial
                  setupClientSecret={setupClientSecret}
                  payment_uuid={props.payment_uuid}
                  setupIntentId={setupIntentId}
                />
              </Elements>
            </div>
          </Col>
        )}
      </Row>

      {/* Toasts and Alerts */}
      <Toast
        className="position-fixed top-0 end-0 m-3"
        show={errorToast}
        style={{ zIndex: 1050 }}
      >
        <Toast.Body>Something went wrong!</Toast.Body>
      </Toast>

      <Alert
        variant="danger"
        className="position-fixed top-0 start-50 translate-middle-x m-3"
        show={errorAlert}
        style={{ zIndex: 1050 }}
      >
        Something Went Wrong
      </Alert>
    </Container>
  );
};

export default SubscriptionWithTrial;
