import {
  CardElement,
  Elements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Form, Spinner } from "react-bootstrap";
import countriesData from "../json/countries.json";
import { useState } from "react";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const countries = JSON.parse(JSON.stringify(countriesData));
const AddPaymentMethodForm = (props) => {
  const { customer } = useParams();
  const elements = useElements();
  const [country, setCountry] = useState("AF");
  const [error, setError] = useState(false);
  const stripe = useStripe(process.env.REACT_APP_PUBLISHABLE_KEY, {
    stripeAccount: props.accountId,
  });
  const [loaidng, setLoading] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      setError("Stripe has not loaded correctly.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Create payment method with billing details
      const { paymentMethod, error: paymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            address: {
              country: country,
            },
          },
        });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message);
      }

      // Save payment method to your backend
      const response = await AxiosInstanceMENACartServer.post(
        `/api/v2/payment_method/create`,
        {
          customer_id: customer,
          payment_method_id: paymentMethod.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 && response.data.payment_method) {
        props.setPaymentMethodUpdated(true);
      } else {
        setError("Failed to update the payment method.");
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div style={{ minHeight: "50px" }}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  iconColor: "red",
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#fce883",
                  },
                  "::placeholder": {
                    color: "#87BBFD",
                  },
                  padding: "0.25rem",
                },
                invalid: {
                  iconColor: "#FFC7EE",
                  color: "#FFC7EE",
                },
              },
            }}
          />
        </div>
        <Form.Group className="mb-3">
          <label>Country</label>
          <Form.Select
            defaultValue="AF"
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          >
            {countries.map((item) => (
              <option value={item.code} key={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={loaidng}
            style={{
              backgroundColor: props.buttonColor,
              color: props.buttonTextColor,
              borderColor: props.buttonColor,
              padding: "0.5rem 2rem",
              minWidth: "118",
            }}
            type="submit"
          >
            {loaidng ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
      {error && (
        <span
          style={{
            color: "red",
            textAlign: "center",
            width: "100%",
            display: "block",
            marginTop: "1rem",
          }}
        >
          Something went wrong
        </span>
      )}
    </>
  );
};

export default AddPaymentMethodForm;
