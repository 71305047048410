import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

const QuantityModal = (props) => {
  const [show, setShow] = useState(props?.show);
  const [quantityValue, setQuantityValue] = useState(props?.quantity);
  const [disabled, setDisabled] = useState(false);
  const [exception, setException] = useState("");
  const [stockLimitWarning, setStockLimitWarning] = useState(false);

  const handleClose = () => props.setShow(false);

  const handleUpdate = (event) => {
    event.preventDefault();
    setStockLimitWarning(false);

    // Check for currency-based quantity and amount limits
    if (
      (exception === "" && quantityValue * props?.price.amount > 999999.99) ||
      (exception === "" && quantityValue > 99) ||
      (exception === "jod" && quantityValue * props?.price.amount > 709191.18) ||
      (exception === "jod" && quantityValue > 99) ||
      (exception === "idr" && quantityValue * props?.price.amount > 9999999999.99) ||
      (exception === "idr" && quantityValue > 99)
    ) {
      setDisabled(true);
    } else if (quantityValue > props?.price?.quantity) {
      setStockLimitWarning(true); // Set warning if quantity exceeds available stock
      setDisabled(true);
    } else {
      if (props?.updatePaymentIntent) {
        props.updatePaymentIntent(quantityValue);
      }
      props.setQuantity(quantityValue);
      if (props?.createPaymentIntent) {
        props.createPaymentIntent(quantityValue);
      }
      handleClose();
    }
  };

  useEffect(() => {
    if (props?.price?.original_currency_code === "jod") {
      setException("jod");
    } else if (props?.price?.original_currency_code === "idr") {
      setException("idr");
    }
  }, [props?.price?.original_currency_code]);

  const handleChange = (e) => {
    const quantity = Number(e.target.value);
    setQuantityValue(quantity);

    // Check if requested quantity exceeds available stock
    if (quantity > props?.price?.quantity) {
      setStockLimitWarning(true);
      setDisabled(true);
    } else {
      setStockLimitWarning(false);
      setDisabled(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>Update Quantity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{ margin: "0" }} onSubmit={handleUpdate}>
          <Form.Control
            type="number"
            min="1"
            step="1"
            defaultValue={props?.quantity}
            onChange={handleChange}
          />
          <Button type="submit" className="mb-2" disabled={disabled}>
            Update
          </Button>
          {stockLimitWarning ? (
            <span className="text-danger m-1">
              <strong>Warning!</strong> Quantity exceeds available stock.
            </span>
          ) : disabled ? (
            <span className="text-muted m-1">
              <strong>Warning!</strong> Quantity Exceeds the Limit
            </span>
          ) : null}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuantityModal;
